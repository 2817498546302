export default {
  route: {
    platmanagemtnt: '平台管理',    
    branchlist: '分店清单',
    fabriclist: '面料商清单',
    myaccount:'资金账户',
    designers: '入驻版师清单',
    manufacturers: '成衣生产厂商',    
    rights: '角色权限',    
    coupon: '平台优惠券',
    images: '客户的杂图',
    datainit: '分店初始化数据',
    templatedetails:'系统集成模板详情',
    sharedimgs: '共享版型图片',
    orgdetails: '分店详情',
    productDetails: '版型详情',
    clothSize: '服装尺码',
    ordersTitle: '订单管理',
    platform:{
      platforminfo: '平台信息',
      accounts: '平台账号',
      orgrights:'分店C端机构权限',
      feedback: '客户反馈',
    },
    orders: {
      bokesOrder: '电子版订单',
      clothsOrders: '成衣现货订单',
      decorationOders: '饰品订单',
      fabricsOrder: '面辅料订单',
      physicalBokes: '纸质版订单',
      physicalPrinting: '纸质版打印',
      tailoredOrders: '量体订单',
      tailoredBokes: '来图版型订单',
      tailoredCloths: '成衣定制订单',
      clothsOrder: '成衣现货订单',
      tailoredMade: '量体定制版型订单',
      tailoredMadeDetail:'量体定制订单详情',
      videoTeching: '教学课程订单',
      takeOrders:'成衣接单',
      upgration:'会员升级充值订单',
      autoscaleOrders:'智能量体订单'
    },
    customers:{
      customers:'客户管理',
      levels:'会员等级',
      notes:'平台优惠券',
      extention:'会员续期审核',
    },
    audited:{
      ref:'版型引用审核',
      shared:'版型共享审核',
      settings:'审核设置',
      fabrics:'面料辅料审核',
      decorations:'服装饰品审核',
      bokes:'版型成衣审核',
      upShare:'平台售卖审核'
    },
    products: {
      bokes: '版型管理',
      branchProducts:'版型管理',
      cloths: '成衣管理',
      clothDetails: '成衣明细',
      parentSharedprds:'平台商品',
      upsharedProducts:'分店版型平台售卖',
      fabrics: '面料管理',
      upshare:'分店上平台',
      fabricDetails:'面料详情',
      accessories:'辅料管理',
      decorationDetails:'饰品详情',
      accessoryDetails:'辅料详情',
      decoration:'饰品管理',
      binding:'批量绑定',
      accessoriesDetails:'辅料详情'
    },
    systemTitle: '系统设置',
    system: {
      clothCategory: '商品类别',
      clothStyle: '服装风格',
      clothSize: '服装尺码',
      productUnit: '商品单位',
      clothSizeViewer: '服装尺码详情',
      videos:'帮助视频',
      settings:'系统参数设置',
      operationLogs:'操作日志',
      fabricCategories:'面料分类',
      messages:'全站消息',
      clothSizeTemplate:'尺码模板管理',
      depositeSettings:'账户充值设置',
      course:'教学课程'
    },
    inventoryTitle:'库存管理',
    inventory:{
      storage:'商品库存',
      instore:'入库详情',
      outstore:'出库详情',
    },
    financial:{
      recharge:'会员购买记录',
      accountStatements:'流水记录',
      withdrawal:'资金提现',
      depositeRecords:'账户充值记录',
      depositeHistories:'账户充值设置'
    }
  },
  
  
}
